import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MapboxGeocoder from "@mapbox/mapbox-sdk/services/geocoding";
import "mapbox-gl/dist/mapbox-gl.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isSameDay, parseISO, format } from "date-fns";
import axios from "./axiosInstance";
import useAuthToken from "../hooks/useAuthToken";
import { APIURL, APPNAME, isEmpty, MAP_API_KEY } from "../Constants";
import { LoaderContext } from "./LoaderContext";
import { calculateDefaultDateTime } from "../utils/dateUtils";

import background1 from "../images/background/1.jpg";
import background2 from "../images/background/2.jpg";
import airportIcon from "../images/select-form/airport.png";
import outstationIcon from "../images/select-form/travel.png";
import clockIcon from "../images/select-form/clock.png";
import miscCarIcon from "../images/misc/car.png";

const libraries = ["places"];

const Home = () => {
  const { setHideLoader } = useContext(LoaderContext);
  const token = useAuthToken();
  const navigate = useNavigate();
  const [cityList, setCityList] = useState([]);
  const [onewayCityList, setOnewayCityList] = useState([]);
  const [localPackagesList, setLocalPackagesList] = useState([]);
  const [homeConfig, setHomeConfig] = useState(null);
  const [formValidationError, setFormValidationError] = useState(null);
  const [activeAirportList, setActiveAirportList] = useState(null);
  const [activeSearchForm, setActiveSearchForm] = useState("airportTransfer");
  const [activeSubType, setActiveSubType] = useState("airportTransferAirportDrop");
  const [formValue, setFormValue] = useState(null);

  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState({});
  const geocoder = useRef(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("00:00");

  const inputRefs = useRef({});
  const disabledDates = [].map((date) => parseISO(date));
  const isDateDisabled = (date) => {
    return disabledDates.some((disabledDate) => isSameDay(date, disabledDate));
  };

  const pickupCity = { fieldName: "pickupCity", labelName: "Pickup City", inputType: "city" };
  const airportTerminal = {
    fieldName: "airportTerminal",
    labelName: "Airport Terminal",
    inputType: "airport",
  };
  const pickupLocation = {
    fieldName: "pickupLocation",
    labelName: "Pickup Location",
    inputType: "pickupLocation",
  };
  const dropLocation = {
    fieldName: "dropLocation",
    labelName: "Drop Location",
    inputType: "dropLocation",
  };
  const pickupDate = {
    fieldName: "pickupDate",
    labelName: "Pickup Date & Time",
    inputType: "date",
  };
  const localPackages = {
    fieldName: "localPackages",
    labelName: "Local Packages",
    inputType: "packages",
  };
  const dropCity = {
    fieldName: "dropCity",
    labelName: "Drop City",
    inputType: "dropCity",
  };
  const onewayDropCity = {
    fieldName: "dropCity",
    labelName: "Drop City",
    inputType: "onewayDropCity",
  };
  const pickupDropDate = {
    fieldName: "pickupDropDate",
    labelName: "Pickup & Drop Date",
    inputType: "pickupDropDate",
  };
  const pickupTime = {
    fieldName: "pickupTime",
    labelName: "Pickup Time",
    inputType: "pickupTime",
  };

  const searchFormConfig = {
    airportTransferAirportDrop: [pickupCity, airportTerminal, pickupLocation, pickupDate],
    airportTransferCityDrop: [pickupCity, airportTerminal, dropLocation, pickupDate],
    localPackages: [pickupCity, pickupLocation, localPackages, pickupDate],
    outstationOneway: [pickupCity, pickupLocation, onewayDropCity, pickupDate],
    outstationRoundTrip: [pickupCity, pickupLocation, dropCity, pickupDropDate, pickupTime],
  };

  const getHomeConfig = async () => {
    if (token) {
      setHideLoader(false);
      const response = await axios.get(`${APIURL}get_home_config`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setHideLoader(true);
        setCityList(response?.data?.cityData ?? []);
        setLocalPackagesList(response?.data?.localPackages ?? []);
        setOnewayCityList(response?.data?.onewayCityList ?? []);
        setHomeConfig(response?.data?.homeConfig ?? null);
      }
    }
  };

  const searchCabs = async () => {
    if (searchFormConfig?.[activeSubType]) {
      setHideLoader(false);
      let searchFormValue = { subType: activeSubType };
      let formErrors = "";

      searchFormConfig?.[activeSubType]?.map((field) => {
        let formDataValue = formValue?.[field?.fieldName];

        if (field?.fieldName === "pickupDate" || field?.fieldName === "dropDate") {
          formDataValue = formValue?.[`${field?.fieldName}Formatted`];
        }

        if (!formDataValue || formDataValue === "") {
          formErrors = `${formErrors} ${field?.labelName} is required.`;
        } else {
          searchFormValue = {
            ...searchFormValue,
            [field?.fieldName]: formDataValue,
          };
        }
      });
      if (!isEmpty(formErrors)) {
        setFormValidationError(`<p className="badge bg-danger">${formErrors}</p>`);
      } else {
        setFormValidationError("");

        let newFormValues = {};
        if (activeSubType === "airportTransferAirportDrop") {
          newFormValues = {
            origin: searchFormValue?.pickupLocation,
            destination: searchFormValue?.airportTerminal,
            startAddress: searchFormValue?.pickupLocation,
            endAddress: searchFormValue?.airportTerminal,
          };
        } else if (activeSubType === "airportTransferCityDrop") {
          newFormValues = {
            origin: searchFormValue?.airportTerminal,
            destination: searchFormValue?.dropLocation,
            startAddress: searchFormValue?.airportTerminal,
            endAddress: searchFormValue?.dropLocation,
          };
        } else if (activeSubType === "localPackages") {
          const [hours, kilometers] = searchFormValue?.localPackages?.split("|");
          newFormValues = {
            origin: searchFormValue?.pickupLocation,
            destination: searchFormValue?.pickupLocation,
            startAddress: searchFormValue?.pickupLocation,
            endAddress: searchFormValue?.pickupLocation,
            localPackagesStr: `${hours} hr ${kilometers} km`,
            localPackageHrs: hours,
            localPackageKilometers: kilometers,
          };
        } else if (activeSubType === "outstationOneway") {
          const [dropCityId, dropCityName] = searchFormValue?.dropCity?.split("|");
          newFormValues = {
            origin: searchFormValue?.pickupLocation,
            destination: dropCityName,
            startAddress: searchFormValue?.pickupLocation,
            endAddress: dropCityName,
            dropCityId: dropCityId,
          };
        } else if (activeSubType === "outstationRoundTrip") {
          newFormValues = {
            origin: searchFormValue?.pickupLocation,
            destination: searchFormValue?.dropCity,
            startAddress: searchFormValue?.pickupLocation,
            endAddress: searchFormValue?.dropCity,
          };
        }
        newFormValues = { ...searchFormValue, ...newFormValues };
        const encodedData = btoa(JSON.stringify(newFormValues));
        navigate(`/search?data=${encodedData}`);
      }
      setHideLoader(true);
    }
  };

  const clearInputValue = () => {
    Object.keys(inputRefs.current).forEach((key) => {
      if (inputRefs.current[key]) {
        inputRefs.current[key].value = "";
      }
    });
  };

  const handleFormChangeValue = async (value, fieldName) => {
    let oldFormValue = { ...formValue };
    let formattedValue = value;
    if (fieldName === "pickupCity") {
      const activeCitydata = cityList.find((city) => city?.cityId === formattedValue);
      oldFormValue = { ...oldFormValue, airportTerminal: "" };
      setActiveAirportList(activeCitydata?.airports ?? []);

      setFormValue({
        ...oldFormValue,
        [fieldName]: formattedValue,
        cityName: activeCitydata?.cityName,
      });

      // const { defaultDate, defaultTime } = calculateDefaultDateTime(
      //   activeCitydata?.postBookingDays ?? 0,
      //   activeCitydata?.postBookingHours ?? "0",
      // );
      // setSelectedDate(defaultDate);
      // setSelectedTime(defaultTime);
    } else if (
      fieldName === "pickupLocation" ||
      fieldName === "dropLocation" ||
      fieldName === "dropCity"
    ) {
      // const searchBox = searchBoxes[fieldName];
      // if (searchBox) {
      //   const places = searchBox.getPlaces();
      //   const place = places?.[0];
      //   formattedValue = place?.formatted_address;
      // }
      setQuery({ ...query, [fieldName]: value });
      setSuggestions({});
      setFormValue({ ...formValue, [fieldName]: value });
    } else if (fieldName === "pickupDate" || fieldName === "dropDate") {
      const parsedDate = new Date(value);
      const formattedDate = format(parsedDate, "yyyy-MM-dd HH:mm");

      setFormValue({
        ...oldFormValue,
        [`${fieldName}Formatted`]: formattedDate,
        [fieldName]: value,
      });
    } else if (fieldName === "pickupDropDate") {
      const [start, end] = formattedValue;

      setFormValue({
        ...oldFormValue,
        [fieldName]: formattedValue,
        [`${fieldName}Start`]: start,
        [`${fieldName}StartFormatted`]: format(start, "yyyy-MM-dd"),
        [`${fieldName}End`]: end,
        [`${fieldName}EndFormatted`]: end ? format(end, "yyyy-MM-dd") : "",
      });
    } else {
      setFormValue({ ...oldFormValue, [fieldName]: formattedValue });
    }
  };

  const handleInputChange = async (e, fieldName) => {
    if (!formValue?.cityName) {
      setFormValidationError(`<p className="badge bg-danger">Pickup City is required</p>`);
    } else {
      setFormValidationError("");
      const value = e.target.value;
      setQuery({ ...query, [fieldName]: value });

      if (value) {
        try {
          const response = await geocoder.current
            .forwardGeocode({
              query: `${value}, ${formValue?.cityName}, India`,
              limit: 5,
            })
            .send();

          if (response && response.body && response.body.features) {
            setSuggestions({ ...suggestions, [fieldName]: response.body.features });
          }
        } catch (error) {
          console.error("Error fetching geocoding data:", error);
        }
      } else {
        setSuggestions({});
      }
    }
  };

  useEffect(() => {
    if (activeSubType) {
      clearInputValue();
      const parsedDate = new Date(selectedDate);
      const formattedDate = format(parsedDate, "yyyy-MM-dd HH:mm");
      setFormValue({
        ...formValue,
        dropLocation: null,
        pickupLocation: null,
        pickupDate: selectedDate,
        pickupDateFormatted: formattedDate,
        pickupDropDate: selectedDate,
        pickupDropDateStart: selectedDate,
        pickupDropDateStartFormatted: formattedDate,
        pickupDropDateEnd: selectedDate,
        pickupDropDateEndFormatted: formattedDate,
        pickupTime: selectedDate,
      });
    }
  }, [activeSubType]);

  useEffect(() => {
    if (token) {
      getHomeConfig();
    }
  }, [token]);

  useEffect(() => {
    geocoder.current = MapboxGeocoder({
      accessToken: MAP_API_KEY,
    });
  }, []);

  return (
    <>
      <section id="section-hero" aria-label="section" className="jarallax">
        <img src={background1} className="jarallax-img" alt={APPNAME} />
        <div className="container position-relative z1000">
          <div className="row align-items-center">
            <div className="col-lg-6 text-light hide-in-mobile">
              <h4>
                xw
                <span className="id-color">Fast and Easy Way to Rent a Car</span>
              </h4>
              <div className="spacer-10"></div>
              <h1 className="mb-2">Explore the world with comfortable car</h1>
              <div className="spacer-10"></div>
              <p className="lead">
                Embark on unforgettable adventures and discover the world in unparalleled comfort
                and style with our fleet of exceptionally comfortable cars.
              </p>
            </div>

            <div className="col-lg-6">
              <div className="spacer-single sm-hide"></div>
              <div className="p-4 rounded-3 shadow-soft search-panel-outer" data-bgcolor="#ffffff">
                <form name="contactForm" id="contact_form" method="post">
                  <h5>Select your duty type?</h5>

                  <div className="de_form de_radio row g-3">
                    <div className="radio-img col-lg-3 col-sm-3 col-6">
                      <input
                        id="radio-1a"
                        name="Car_Type"
                        onClick={() => {
                          setActiveSearchForm("airportTransfer");
                          setActiveSubType("airportTransferAirportDrop");
                        }}
                        type="radio"
                        value="airportTransfer"
                        checked={activeSearchForm === "airportTransfer" ? "checked" : ""}
                      />
                      <label htmlFor="radio-1a">
                        <img src={airportIcon} alt="Airport Transfer" />
                        Airport Transfer
                      </label>
                    </div>

                    <div className="radio-img col-lg-3 col-sm-3 col-6">
                      <input
                        id="radio-1b"
                        name="Car_Type"
                        onClick={() => {
                          setActiveSearchForm("localPackages");
                          setActiveSubType("localPackages");
                        }}
                        type="radio"
                        value="localPackages"
                        checked={activeSearchForm === "localPackages" ? "checked" : ""}
                      />
                      <label htmlFor="radio-1b">
                        <img src={clockIcon} alt="Local Packages" />
                        Local Packages
                      </label>
                    </div>

                    <div className="radio-img col-lg-3 col-sm-3 col-6">
                      <input
                        id="radio-1c"
                        name="Car_Type"
                        onClick={() => {
                          setActiveSearchForm("outstation");
                          setActiveSubType("outstationOneway");
                        }}
                        type="radio"
                        value="outstation"
                        checked={activeSearchForm === "outstation" ? "checked" : ""}
                      />
                      <label htmlFor="radio-1c">
                        <img src={outstationIcon} alt="Outstation" />
                        Outstation Packages
                      </label>
                    </div>

                    <div className="radio-img col-lg-3 col-sm-3 col-6">
                      <input
                        id="radio-1c"
                        name="Car_Type"
                        onClick={() => {
                          setActiveSearchForm("pointToPoint");
                          setActiveSubType("pointToPoint");
                        }}
                        type="radio"
                        value="pointToPoint"
                        checked={activeSearchForm === "pointToPoint" ? "checked" : ""}
                      />
                      <label htmlFor="radio-1c">
                        <img src={outstationIcon} alt="pointToPoint" />
                        Point To Point
                      </label>
                    </div>
                  </div>

                  <div className="spacer-20"></div>

                  <div className="row">
                    {activeSearchForm === "airportTransfer" && (
                      <div className="col-lg-12 mb20">
                        <h5>Airport Transfer Type</h5>
                        <div className="form-check" style={{ display: "inline-block" }}>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radioairportDrop"
                            name="airportTransferType"
                            value="airportTransferAirportDrop"
                            onClick={() => setActiveSubType("airportTransferAirportDrop")}
                            checked={
                              activeSubType === "airportTransferAirportDrop" ? "checked" : ""
                            }
                          />
                          Airport Drop
                          <label className="form-check-label" htmlFor="radio1"></label>
                        </div>
                        <div className="form-check" style={{ display: "inline-block" }}>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radiocityDrop"
                            name="airportTransferType"
                            value="airportTransferCityDrop"
                            onClick={() => setActiveSubType("airportTransferCityDrop")}
                            checked={activeSubType === "airportTransferCityDrop" ? "checked" : ""}
                          />
                          City Drop<label className="form-check-label" htmlFor="radio2"></label>
                        </div>
                      </div>
                    )}

                    {activeSearchForm === "outstation" && (
                      <div className="col-lg-12 mb10">
                        <h5>Outstation Transfer Type</h5>
                        <div className="form-check" style={{ display: "inline-block" }}>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radiooneway"
                            name="outstationTransferType"
                            value="outstationOneway"
                            onClick={() => setActiveSubType("outstationOneway")}
                            checked={activeSubType === "outstationOneway" ? "checked" : ""}
                          />
                          Oneway<label className="form-check-label" htmlFor="radio1"></label>
                        </div>
                        <div className="form-check" style={{ display: "inline-block" }}>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radioroundtrip"
                            name="outstationTransferType"
                            value="outstationRoundTrip"
                            onClick={() => setActiveSubType("outstationRoundTrip")}
                            checked={activeSubType === "outstationRoundTrip" ? "checked" : ""}
                          />
                          Round Trip
                          <label className="form-check-label" htmlFor="radio2"></label>
                        </div>
                      </div>
                    )}

                    {searchFormConfig?.[activeSubType] &&
                      searchFormConfig[activeSubType].map((config) => (
                        <div className="col-lg-6 mb10">
                          <h5>{config.labelName}</h5>
                          {(config?.inputType === "pickupLocation" ||
                            config?.inputType === "dropLocation") && (
                            <>
                              <input
                                type="text"
                                value={query[config?.fieldName]}
                                onChange={(e) => handleInputChange(e, config?.fieldName)}
                                placeholder={`Enter your ${config.labelName}`}
                                className="form-control"
                              />
                              {suggestions?.[config?.fieldName]?.length > 0 && (
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: "0",
                                    margin: "0",
                                    position: "absolute",
                                    width: "100%",
                                    maxHeight: "150px",
                                    overflowY: "auto",
                                    border: "1px solid #ccc",
                                    backgroundColor: "#fff",
                                    zIndex: "1000",
                                  }}
                                >
                                  {suggestions?.[config?.fieldName]?.map((suggestion) => (
                                    <li
                                      key={suggestion.id}
                                      onClick={() =>
                                        handleFormChangeValue(
                                          suggestion?.place_name,
                                          config?.fieldName,
                                        )
                                      }
                                      style={{ padding: "10px", cursor: "pointer" }}
                                    >
                                      {suggestion.place_name}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          )}

                          {config?.inputType === "city" && (
                            <select
                              className="form-control"
                              name={config?.fieldName}
                              onChange={(e) =>
                                handleFormChangeValue(e.target.value, config?.fieldName)
                              }
                            >
                              <option disabled selected>
                                Select {config.labelName}
                              </option>
                              {cityList.map((city) => (
                                <option key={city?.cityId} value={city?.cityId}>
                                  {city?.cityName}
                                </option>
                              ))}
                            </select>
                          )}

                          {config?.inputType === "airport" && (
                            <select
                              className="form-control"
                              name={config?.fieldName}
                              onChange={(e) =>
                                handleFormChangeValue(e.target.value, config?.fieldName)
                              }
                            >
                              <option disabled selected>
                                Select {config.labelName}
                              </option>
                              {activeAirportList &&
                                activeAirportList.map((airport) => (
                                  <option key={airport} value={airport}>
                                    {airport}
                                  </option>
                                ))}
                            </select>
                          )}

                          {config?.inputType === "date" && (
                            <DatePicker
                              selected={
                                formValue?.hasOwnProperty(config?.fieldName)
                                  ? formValue[config?.fieldName]
                                  : selectedDate
                              }
                              onChange={(date) => handleFormChangeValue(date, config?.fieldName)}
                              minDate={selectedDate}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="dd/MM/yyyy h:mm aa"
                              timeCaption="Time"
                              // filterDate={isDateDisabled}
                              placeholderText={config.labelName}
                              name={config?.fieldName}
                              className="form-control"
                            />
                          )}

                          {config?.inputType === "pickupDropDate" && (
                            <DatePicker
                              // selected={
                              //   formValue?.hasOwnProperty(config?.pickupDropDateStart)
                              //     ? formValue[config?.pickupDropDateStart]
                              //     : selectedDate
                              // }
                              onChange={(date) => handleFormChangeValue(date, config?.fieldName)}
                              startDate={
                                formValue?.hasOwnProperty("pickupDropDateStart")
                                  ? formValue["pickupDropDateStart"]
                                  : selectedDate
                              }
                              endDate={
                                formValue?.hasOwnProperty("pickupDropDateEnd")
                                  ? formValue["pickupDropDateEnd"]
                                  : selectedDate
                              }
                              selectsRange
                              selectsDisabledDaysInRange
                              className="form-control"
                            />
                          )}

                          {config?.inputType === "pickupTime" && (
                            <DatePicker
                              selected={
                                formValue?.hasOwnProperty(config?.fieldName)
                                  ? formValue[config?.fieldName]
                                  : selectedDate
                              }
                              onChange={(date) => handleFormChangeValue(date, config?.fieldName)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control"
                            />
                          )}

                          {config?.inputType === "packages" && (
                            <select
                              className="form-control"
                              name={config?.fieldName}
                              onChange={(e) =>
                                handleFormChangeValue(e.target.value, config?.fieldName)
                              }
                            >
                              <option disabled selected>
                                Select {config.labelName}
                              </option>
                              {localPackagesList &&
                                localPackagesList.map((lpackage) => (
                                  <option key={lpackage[0]} value={lpackage[0]}>
                                    {lpackage[1]}
                                  </option>
                                ))}
                            </select>
                          )}

                          {config?.inputType === "onewayDropCity" && (
                            <select
                              className="form-control"
                              name={config?.fieldName}
                              onChange={(e) =>
                                handleFormChangeValue(e.target.value, config?.fieldName)
                              }
                            >
                              <option disabled selected>
                                Select {config.labelName}
                              </option>
                              {onewayCityList.map((city) => (
                                <option
                                  key={city?.cityId}
                                  value={city?.cityId + "|" + city?.cityName}
                                >
                                  {city?.cityName}
                                </option>
                              ))}
                            </select>
                          )}

                          {config?.inputType === "dropCity" && (
                            <>
                              <input
                                type="text"
                                value={query[config?.fieldName]}
                                onChange={(e) => handleInputChange(e, config?.fieldName)}
                                placeholder={`Enter your ${config.labelName}`}
                                className="form-control"
                              />
                              {suggestions?.[config?.fieldName]?.length > 0 && (
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: "0",
                                    margin: "0",
                                    position: "absolute",
                                    width: "100%",
                                    maxHeight: "150px",
                                    overflowY: "auto",
                                    border: "1px solid #ccc",
                                    backgroundColor: "#fff",
                                    zIndex: "1000",
                                  }}
                                >
                                  {suggestions?.[config?.fieldName]?.map((suggestion) => (
                                    <li
                                      key={suggestion.id}
                                      onClick={() =>
                                        handleFormChangeValue(
                                          suggestion?.place_name,
                                          config?.fieldName,
                                        )
                                      }
                                      style={{ padding: "10px", cursor: "pointer" }}
                                    >
                                      {suggestion.place_name}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    <span dangerouslySetInnerHTML={{ __html: formValidationError }} />
                  </div>

                  <input
                    type="button"
                    id="send_message"
                    value="Find a Vehicle"
                    className="btn-main pull-right"
                    onClick={() => searchCabs()}
                  />

                  <div className="clearfix"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        aria-label="section"
        className="pt40 pb40 text-light de-marquee-list-outer"
        data-bgcolor="#111111"
      >
        <div className="fadeInRight d-flex">
          <div className="de-marquee-list">
            <div>
              <span className="d-item-txt">SUV</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Hatchback</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Crossover</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Convertible</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Sedan</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Sports Car</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Coupe</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Minivan</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Station Wagon</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Truck</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Minivans</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Exotic Cars</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
            </div>
          </div>

          <div className="de-marquee-list">
            <div>
              <span className="d-item-txt">SUV</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Hatchback</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Crossover</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Convertible</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Sedan</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Sports Car</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Coupe</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Minivan</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Station Wagon</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Truck</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Minivans</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
              <span className="d-item-txt">Exotic Cars</span>
              <span className="d-item-display">
                <i className="d-item-dot"></i>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section aria-label="section" id="why-choose-div">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 offset-lg-3 text-center">
              <span className="subtitle">Why Choose Us</span>
              <h2>Our Features</h2>
              <p>
                Discover a world of convenience, safety, and customization, paving the way for
                unforgettable adventures and seamless mobility solutions.
              </p>
              <div className="spacer-20"></div>
            </div>
            <div className="clearfix"></div>
            <div className="col-lg-3">
              <div className="box-icon s2 p-small mb20 fadeInRight" data-wow-delay=".5s">
                <i className="fa bg-color fa-trophy"></i>
                <div className="d-inner">
                  <h4>First class services</h4>
                  Where luxury meets exceptional care, creating unforgettable moments and exceeding
                  your every expectation.
                </div>
              </div>
              <div className="box-icon s2 p-small mb20 fadeInL fadeInRight" data-wow-delay=".75s">
                <i className="fa bg-color fa-road"></i>
                <div className="d-inner">
                  <h4>24/7 road assistance</h4>
                  Reliable support when you need it most, keeping you on the move with confidence
                  and peace of mind.
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <img src={miscCarIcon} alt={APPNAME} className="img-fluid fadeInUp" />
            </div>

            <div className="col-lg-3">
              <div
                className="box-icon s2 d-invert p-small mb20 fadeInL fadeInLeft"
                data-wow-delay="1s"
              >
                <i className="fa bg-color fa-tag"></i>
                <div className="d-inner">
                  <h4>Quality at Minimum Expense</h4>
                  Unlocking affordable brilliance with elevating quality while minimizing costs for
                  maximum value.
                </div>
              </div>
              <div
                className="box-icon s2 d-invert p-small mb20 fadeInL fadeInLeft"
                data-wow-delay="1.25s"
              >
                <i className="fa bg-color fa-map-pin"></i>
                <div className="d-inner">
                  <h4>Free Pick-Up & Drop-Off</h4>
                  Enjoy free pickup and drop-off services, adding an extra layer of ease to your car
                  rental experience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-light jarallax">
        <img src={background2} className="jarallax-img" alt={APPNAME} />
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 fadeInRight">
              <h2>
                We offer customers a wide range of <span className="id-color">commercial cars</span>{" "}
                and <span className="id-color">luxury cars</span> for any occasion.
              </h2>
            </div>
            <div className="col-lg-6 fadeInLeft">
              At our car rental agency, we believe that everyone deserves to experience the pleasure
              of driving a reliable and comfortable vehicle, regardless of their budget. We have
              curated a diverse fleet of well-maintained cars, ranging from sleek sedans to spacious
              SUVs, all at competitive prices. With our streamlined rental process, you can quickly
              and conveniently reserve your desired vehicle. Whether you need transportation for a
              business trip, family vacation, or simply want to enjoy a weekend getaway, we have
              flexible rental options to accommodate your schedule.
            </div>
          </div>
          <div className="spacer-double"></div>
          <div className="row text-center">
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light fadeInUp">
                <h3 className="timer" data-to="15425" data-speed="3000">
                  12345+
                </h3>
                Completed Orders
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light fadeInUp">
                <h3 className="timer" data-to="8745" data-speed="3000">
                  400+
                </h3>
                Happy Customers
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light fadeInUp">
                <h3 className="timer" data-to="235" data-speed="3000">
                  120
                </h3>
                Vehicles Fleet
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light fadeInUp">
                <h3 className="timer" data-to="15" data-speed="3000">
                  15+
                </h3>
                Years Experience
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section-faq">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h2>Have Any Questions?</h2>
              <div className="spacer-20"></div>
            </div>
          </div>
          <div className="row g-custom-x">
            <div className="col-md-6 fadeInUp">
              <div className="accordion secondary">
                <div className="accordion-section">
                  <div className="accordion-section-title" data-tab="#accordion-1">
                    How do I get started with Car Rental?
                  </div>
                  <div className="accordion-section-content" id="accordion-1">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                      praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                      excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                      officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                      quidem rerum facilis est et expedita distinctio.
                    </p>
                  </div>
                  <div className="accordion-section-title" data-tab="#accordion-2">
                    Can I rent a car with a debit card??
                  </div>
                  <div className="accordion-section-content" id="accordion-2">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                      praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                      excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                      officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                      quidem rerum facilis est et expedita distinctio.
                    </p>
                  </div>
                  <div className="accordion-section-title" data-tab="#accordion-3">
                    What kind of Car Rental do I need?
                  </div>
                  <div className="accordion-section-content" id="accordion-3">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                      praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                      excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                      officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                      quidem rerum facilis est et expedita distinctio.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 fadeInUp">
              <div className="accordion secondary">
                <div className="accordion-section">
                  <div className="accordion-section-title" data-tab="#accordion-b-4">
                    What is a rental car security deposit?
                  </div>
                  <div className="accordion-section-content" id="accordion-b-4">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                      praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                      excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                      officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                      quidem rerum facilis est et expedita distinctio.
                    </p>
                  </div>
                  <div className="accordion-section-title" data-tab="#accordion-b-5">
                    Can I cancel or modify my reservation?
                  </div>
                  <div className="accordion-section-content" id="accordion-b-5">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                      praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                      excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                      officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                      quidem rerum facilis est et expedita distinctio.
                    </p>
                  </div>
                  <div className="accordion-section-title" data-tab="#accordion-b-6">
                    Is it possible to extend my rental period?
                  </div>
                  <div className="accordion-section-content" id="accordion-b-6">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                      praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                      excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                      officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                      quidem rerum facilis est et expedita distinctio.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section-call-to-action" className="bg-color-2 pt60 pb60 text-light">
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-2">
                <span className="subtitle text-white">Call us for further information</span>
                <h2 className="s2">Rentaly customer care is here to help you anytime.</h2>
              </div>

              <div className="col-lg-4 text-lg-center text-sm-center">
                <div className="phone-num-big">
                  <i className="fa fa-phone"></i>
                  <span className="pnb-text">Call Us Now</span>
                  <span className="pnb-num">1 200 333 800</span>
                </div>
                <a href="#" className="btn-main">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
